










import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import COMPARE_TO from '@/modules/deep-analysis/constants/compare-to-filter.constant';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '@/modules/deep-analysis/deep-analysis-cluster.service';

@Component({
    filters: {
        DateFilter: (date: Date) => moment(date).format('MMM, D YYYY'),
    },
})
export default class DeepAnalysisClusterInfo extends Vue {
    @Inject(DeepCompsetAnalysisClusterServiceS) deepCompsetAnalysisClusterService!: DeepCompsetAnalysisClusterService;

    get text() {
        const isBook = this.deepCompsetAnalysisClusterService.showBy.startsWith('book');
        return isBook
            ? 'were made during the last 7 days' : 'will stay at your property during the next 90 days';
    }

    get comparedValueName() {
        const { compareTo } = this.deepCompsetAnalysisClusterService;
        return compareTo === COMPARE_TO.COMPSET_AVG ? 'CompSet Avg' : 'Last Year';
    }
}

