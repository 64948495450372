








import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import VIEW_TYPE from '@/modules/deep-analysis/constants/view-type-filter.constant';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '@/modules/deep-analysis/deep-analysis-cluster.service';

@Component({
    components: { CustomSelect },
})
export default class ViewFilter extends Vue {
    @Inject(DeepCompsetAnalysisClusterServiceS) deepCompsetAnalysisClusterService!: DeepCompsetAnalysisClusterService;

    get value() {
        return this.deepCompsetAnalysisClusterService.viewType;
    }

    set value(value: VIEW_TYPE) {
        this.deepCompsetAnalysisClusterService.viewType = value;
    }

    get items(): Item[] {
        return [{
            value: VIEW_TYPE.ABSOLUTE,
            name: 'Absolute',
            disabled: false,

        }, {
            value: VIEW_TYPE.PERFORMANCE,
            name: 'Performance',
            disabled: false,
        }];
    }

    get viewType() {
        return this.deepCompsetAnalysisClusterService.viewType === VIEW_TYPE.ABSOLUTE ? '#' : '%';
    }
}
