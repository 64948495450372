

















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import ClusterTooltip from '@/modules/deep-analysis/components/cluster/deep-analysis-cluster-tooltip.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
// import CurrencySwitcher from '@/modules/common/components/currency-switcher.vue';

@Component({
    components: {
        DateDocumentFilter,
        ClusterTooltip,
        // CurrencySwitcher,
    },
})
export default class DeepAnalysisHeader extends Vue {
    @Inject(UserServiceS) private userService!: UserService;

    infoTooltip: boolean = false;

    get name() {
        const { user } = this.userService;
        return user
            ? user.chainName
            : null;
    }
}
