












































import { Inject } from 'inversify-props';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Tooltip from '@/modules/common/components/ui-kit/tooltip.vue';
import TotalIndicatorData from '@/modules/deep-analysis/interfaces/total-indicator.interface';
import STATISTIC_NAMES from '@/modules/deep-analysis/constants/statistic-names.constant';
import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';
import DeepAnalysisItem from '@/modules/deep-analysis/components/hotel/deep-analysis-item.vue';
import SortingButtons from '@/modules/common/components/ui-kit/sorting-buttons.vue';
import TotalIndicator from '@/modules/deep-analysis/components/cluster/deep-analysis-total-indicator.vue';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '@/modules/deep-analysis/deep-analysis-cluster.service';

@Component({
    components: {
        DeepAnalysisItem,
        SortingButtons,
        TotalIndicator,
        Tooltip,
    },
})
export default class HotelsColumnHeader extends Vue {
    @Inject(DeepCompsetAnalysisClusterServiceS) deepCompsetAnalysisClusterService!: DeepCompsetAnalysisClusterService;

    @Prop({
        type: String,
    })
    private statistic!: STATISTIC_TYPE;

    @Prop({
        type: Boolean,
    })
    private hasStar!: boolean;

    @Prop({
        type: Boolean,
    })
    private isRoundedBorder!: boolean;

    @Prop({
        type: Boolean,
    })
    private isSortingEnabled!: boolean;

    @Prop({
        type: Boolean,
    })
    private skeleton!: boolean;

    @Prop({
        type: Object,
        default: () => ({}),
    })
    totalData!: TotalIndicatorData;

    @Prop({
        type: Boolean,
    })
    private hasBorder!: boolean;

    get name() {
        return (STATISTIC_NAMES[this.statistic] || 'No name').toUpperCase();
    }

    sort(e: {
        key: STATISTIC_TYPE,
        type: -1 | 1
    }) {
        this.deepCompsetAnalysisClusterService.sort(e.type, e.key);
    }
}
