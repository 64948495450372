














import DeepAnalysisClusterInfo from '@/modules/deep-analysis/components/cluster/deep-analysis-cluster-info-text.vue';
import DeepAnalysisClusterTable from '@/modules/deep-analysis/components/cluster/deep-analysis-cluster-table.vue';
import { Vue, Component } from 'vue-property-decorator';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';
import DeepAnalysisHeader from '@/modules/deep-analysis/components/cluster/deep-analysis-header.vue';
import DeepAnalysisActions from '@/modules/deep-analysis/components/cluster/deep-analysis-actions.vue';

@Component({
    components: {
        PageWrapper,
        CustomLoader,
        DeepAnalysisHeader,
        DeepAnalysisActions,
        DeepAnalysisClusterInfo,
        DeepAnalysisClusterTable,
    },
})
export default class DeepAnalysisPage extends Vue {}
