






import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '@/modules/deep-analysis/deep-analysis-cluster.service';
import DeepCompsetAnalysisService, { DeepCompsetAnalysisServiceS } from '@/modules/deep-analysis/deep-analysis.service';

@Component
export default class ExcelBtn extends Vue {
    @Inject(DeepCompsetAnalysisClusterServiceS) private deepCompsetAnalysisClusterService!: DeepCompsetAnalysisClusterService;
    @Inject(DeepCompsetAnalysisServiceS) private deepCompsetAnalysisService!: DeepCompsetAnalysisService;

    @Prop({
        type: Boolean,
        default: false,
    })
    isCluster!: boolean;

    async handleClick() {
        if (this.isCluster) {
            await this.deepCompsetAnalysisClusterService.downloadExcel();
        } else {
            await this.deepCompsetAnalysisService.downloadExcel();
        }
    }
}
