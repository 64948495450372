














































import {
    Component, Vue,
} from 'vue-property-decorator';
import CustomTooltip from '@/modules/common/components/ui-kit/custom-tooltip.vue';

@Component({
    components: {
        CustomTooltip,
    },
})
export default class ClusterTooltip extends Vue {}
