





























































































































import { Inject } from 'inversify-props';
import { Vue, Component } from 'vue-property-decorator';
import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';
import HotelIndicator from '@/modules/deep-analysis/components/cluster/deep-analysis-hotel-indicator.vue';
import TotalIndicator from '@/modules/deep-analysis/components/cluster/deep-analysis-total-indicator.vue';
import HotelsColumnHeader from '@/modules/deep-analysis/components/cluster/deep-analysis-column-header.vue';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '@/modules/deep-analysis/deep-analysis-cluster.service';
import DeepCompsetAnalysisService, { DeepCompsetAnalysisServiceS } from '@/modules/deep-analysis/deep-analysis.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';

const SCROLL_SPEED = 35;

@Component({
    components: {
        TotalIndicator,
        HotelIndicator,
        HotelsColumnHeader,
    },
})
export default class DeepAnalysisClusterTable extends Vue {
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(DeepCompsetAnalysisClusterServiceS) dcaClusterService!: DeepCompsetAnalysisClusterService;
    @Inject(DeepCompsetAnalysisServiceS) deepCompsetAnalysisService!: DeepCompsetAnalysisService;
    @Inject(UserServiceS) userService!: UserService;

    public scrollLeft: boolean | any = false;
    public hasScroll: boolean = false;
    public widthAuto: boolean = true;
    public readonly STATISTICS_WITH_SORTING = [STATISTIC_TYPE.REVENUE];
    public scrollInStart: boolean = false;
    public scrollInEnd: boolean = false;

    get isNoData() {
        return !this.skeleton && !this.hotels.length;
    }

    get hotels() {
        let { data } = this.dcaClusterService;

        if (!data || !data.length) {
            return [];
        }

        const [statistic] = this.dcaClusterService.storeState.filterByStatistic;

        if (statistic) {
            data = data
                .filter(item => this.tableData
                    .findIndex(({ name }) => item.hotelName === name) > -1);
        }

        return data.map(hotel => ({
            id: hotel.fornovaId,
            name: hotel.hotelName,
        }));
    }

    get tableData() {
        const [statistic, trend] = this.dcaClusterService.storeState.filterByStatistic;

        if (statistic) {
            return this.dcaClusterService.tableData
                .filter(hotel => {
                    if (!hotel.statistics[statistic]) return false;
                    return hotel.statistics[statistic].diffTrend === trend;
                });
        }

        return this.dcaClusterService.tableData;
    }

    get statistics() {
        return this.dcaClusterService.statistics;
    }

    get skeleton() {
        return !this.hotels.length && this.dcaClusterService.isLoading;
    }

    isSortingEnabled(statistic: STATISTIC_TYPE) {
        return this.STATISTICS_WITH_SORTING.includes(statistic);
    }

    async goToHotel(hotelId: number) {
        const { provider, showBy, compareTo } = this.dcaClusterService;

        await this.userService.switchCurrentHotel(hotelId);
        await this.$router.push({
            name: `${this.$route.name!}.hotel`,
            params: {
                ...this.$route.params,
                hotelId: String(hotelId),
            },
            query: {
                provider,
                showBy,
                compareTo,
            },
        });
    }

    updated() {
        Vue.nextTick(() => {
            const wrapper = this.$refs.wrapper as HTMLElement;
            this.hasScroll = wrapper.scrollHeight > wrapper.clientHeight;
        });
    }

    mounted(): void {
        const wrapper = this.$refs.wrapper as HTMLElement;
        const table = this.$refs.table as HTMLElement;
        this.widthAuto = table.clientWidth >= wrapper.clientWidth;

        this.checkScrollState();
    }

    get isScrollVisible() {
        return this.hasScroll;
    }

    handleScrollMouseRight(e: MouseEvent) {
        if (e.button !== 0) {
            return;
        }
        this.moveLeft(SCROLL_SPEED);
    }

    handleScrollMouseLeft(e: MouseEvent) {
        if (e.button !== 0) {
            return;
        }
        this.moveLeft(-SCROLL_SPEED);
    }

    handleTouchRight() {
        this.moveLeft(SCROLL_SPEED);
    }

    handleTouchLeft() {
        this.moveLeft(-SCROLL_SPEED);
    }

    moveLeft(way: number) {
        const wrapper = this.$refs.wrapper as HTMLElement;
        if (!this.scrollLeft) {
            // eslint-disable-next-line no-return-assign
            this.scrollLeft = setInterval(() => wrapper.scrollLeft += way, 30);
        }
    }

    handleStop() {
        clearInterval(this.scrollLeft);
        this.scrollLeft = false;
    }

    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(e: Event) {
        const targetElement = e.target as HTMLElement;
        const minDistanceToBottom = 10;
        const scrollMax = Math.max(
            targetElement.scrollHeight,
            targetElement.offsetHeight,
            targetElement.clientHeight,
        );

        const scrollCurrent = Math.round(window.innerHeight + Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            targetElement.scrollTop,
        ));

        const isBottomOfWindow = (scrollMax - scrollCurrent < 0)
            || scrollMax - scrollCurrent <= minDistanceToBottom;

        if (isBottomOfWindow) {
            this.dcaClusterService.loadMoreData();
        }

        this.checkScrollState();
    }

    checkScrollState() {
        const { wrapper } = this.$refs as { wrapper: HTMLDivElement };

        this.scrollInStart = wrapper.scrollLeft <= 0;
        this.scrollInEnd = wrapper.scrollLeft >= wrapper.scrollWidth - wrapper.offsetWidth;
    }
}
