






























































import Tooltip from '@/modules/common/components/ui-kit/tooltip.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import HotelStatisticItem from '@/modules/deep-analysis/interfaces/hotel-statistic-item';
import TREND_TYPE from '@/modules/deep-analysis/constants/trend-type.constant';
import Currency from '@/modules/common/components/currency.vue';
import { Inject } from 'inversify-props';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '@/modules/deep-analysis/deep-analysis-cluster.service';
import VIEW_TYPE from '@/modules/deep-analysis/constants/view-type-filter.constant';
import STATISTIC_NAMES from '../../constants/statistic-names.constant';

@Component({
    filters: {
        PriceFilter: (value: number) => (value !== null ? value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : ''),
    },
    components: {
        Currency,
        Tooltip,
    },
})
export default class HotelIndicator extends Vue {
    @Inject(DeepCompsetAnalysisClusterServiceS) deepCompsetAnalysisClusterService!: DeepCompsetAnalysisClusterService;

    @Prop({
        type: Object,
    })
    item!: HotelStatisticItem;

    @Prop({
        type: Boolean,
        required: true,
    })
    hasBorder!: boolean;

    @Prop({
        type: Boolean,
    })
    skeleton!: boolean;

    @Prop({
        type: Boolean,
        required: false,
        default: false,
    })
    isActive!: boolean;

    @Prop({
        type: String,
    })
    statistic!: keyof (typeof STATISTIC_NAMES);

    get diffValue() {
        if (!this.item.diff) {
            return 0;
        }
        return this.item.diff > 0 ? `+${this.item.diff}` : `${this.item.diff}`;
    }

    get statisticLabel() {
        return STATISTIC_NAMES[this.statistic];
    }

    get viewValue() {
        return {
            [VIEW_TYPE.ABSOLUTE]: 'Absolute',
            [VIEW_TYPE.PERFORMANCE]: 'Performance',
        }[this.deepCompsetAnalysisClusterService.viewType];
    }

    formatValue(value: number | null) {
        if (!value) {
            return 0;
        }
        if (this.deepCompsetAnalysisClusterService.viewType === VIEW_TYPE.ABSOLUTE) {
            return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        }
        return value > 0 ? `+${value}` : `${value}`;
    }

    getTrendClass(trend: TREND_TYPE | null) {
        switch (trend) {
            case TREND_TYPE.HIGH:
                return 'increase';
            case TREND_TYPE.lOW:
                return 'decrease';
            default:
                return 'no-change';
        }
    }
}
