












import {
    Prop, Vue, Component,
} from 'vue-property-decorator';

@Component
export default class SortingButtons extends Vue {
    @Prop({
        required: false,
        default: null,
        type: String,
    })
    current!: string | null;

    @Prop({
        required: true,
        type: String,
    })
    name!: string;

    sortType: 1 | -1 | null = null;

    handleSorting(type: 1 | -1) {
        this.sortType = this.sortType === type ? null : type;
        this.$emit('sort', {
            type,
            key: this.name,
        });
    }
}
