var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('Tooltip',{staticClass:"total-indicator",class:{ 'total-indicator--disabled': _vm.skeleton },attrs:{"placement":"bottom","isEmpty":true,"haveArrow":false},scopedSlots:_vm._u([{key:"popover-reference",fn:function(){return [_c('div',{staticClass:"total-indicator__wrapper"},[_c('div',{staticClass:"total-indicator__indicator"},[(_vm.data.decrease)?_c('button',{class:[
                        'total-indicator__proportion',
                        _vm.isDecreaseActive && 'total-indicator__proportion--active',
                        'total-indicator__proportion--decrease'
                    ],style:({ width:  (_vm.decreaseWidth + "%")}),on:{"click":function($event){return _vm.setStatisticFilter('low')}}}):_vm._e(),(_vm.data.noChange)?_c('button',{class:[
                        'total-indicator__proportion',
                        'total-indicator__proportion--no-change' ],on:{"click":function($event){return _vm.setStatisticFilter('no-change')}}}):_vm._e(),(_vm.data.increase)?_c('button',{class:[
                        'total-indicator__proportion',
                        _vm.isIncreaseActive && 'total-indicator__proportion--active',
                        'total-indicator__proportion--increase'
                    ],style:({ width:  (_vm.increaseWidth + "%")}),on:{"click":function($event){return _vm.setStatisticFilter('high')}}}):_vm._e(),(!_vm.data.total)?_c('button',{class:[
                        'total-indicator__proportion',
                        'total-indicator__proportion--no-data',
                        _vm.skeleton && 'skeleton'
                    ],on:{"click":function($event){return _vm.setStatisticFilter(null)}}}):_vm._e()]),(_vm.hasBorder)?_c('div',{staticClass:"total-indicator__border"}):_vm._e()])]},proxy:true},{key:"popover-content",fn:function(){return [_c('div',{staticClass:"total-indicator__tooltip"},[_c('p',{staticClass:"total-indicator__tooltip-title"},[_vm._v(_vm._s(_vm.data.name))]),_c('div',{staticClass:"total-indicator__tooltip-list"},[_c('div',{staticClass:"total-indicator__tooltip-increase"},[_c('div',{staticClass:"total-indicator__tooltip-indicator"}),_c('b',[_vm._v("Increased")]),_c('div',{staticClass:"total-indicator__tooltip-space"}),_c('span',{staticClass:"total-indicator__tooltip-hotels"},[_vm._v(_vm._s(_vm._f("HotelsFilter")(_vm.data.increase)))])]),_c('div',{staticClass:"total-indicator__tooltip-no-change"},[_c('div',{staticClass:"total-indicator__tooltip-indicator"}),_c('b',[_vm._v("No Change")]),_c('div',{staticClass:"total-indicator__tooltip-space"}),_c('span',{staticClass:"total-indicator__tooltip-hotels"},[_vm._v(_vm._s(_vm._f("HotelsFilter")(_vm.data.noChange)))])]),_c('div',{staticClass:"total-indicator__tooltip-decrease"},[_c('div',{staticClass:"total-indicator__tooltip-indicator"}),_c('b',[_vm._v("Decreased")]),_c('div',{staticClass:"total-indicator__tooltip-space"}),_c('span',{staticClass:"total-indicator__tooltip-hotels"},[_vm._v(_vm._s(_vm._f("HotelsFilter")(_vm.data.decrease)))])])])])]},proxy:true}],null,false,3029616100)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }