

















import { Component, Vue } from 'vue-property-decorator';
import ProviderFilter from '@/modules/deep-analysis/components/cluster/actions/provider-filter.vue';
import CompareToFilter from '@/modules/deep-analysis/components/cluster/actions/compare-to-filter.vue';
import ShowByFilter from '@/modules/deep-analysis/components/cluster/actions/show-by-filter.vue';
import ViewFilter from '@/modules/deep-analysis/components/cluster/actions/view-filter.vue';
import Search from '@/modules/common/components/ui-kit/search.vue';
import ExcelBtn from '../deep-analysis-excel.vue';

@Component({
    components: {
        ProviderFilter,
        CompareToFilter,
        ShowByFilter,
        ViewFilter,
        ExcelBtn,
        Search,
    },
})
export default class DeepAnalysisActions extends Vue {}
