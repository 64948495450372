






























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { ChartData, ChartOptions } from 'chart.js';
import moment from 'moment';
import { Inject } from 'inversify-props';
import Price from '@/modules/common/types/price.type';
import { DeepCompsetAnalysisItem } from '@/modules/deep-analysis/interfaces/deep-analisys-item.interface';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import DeepAnalysisIndicator from '@/modules/deep-analysis/components/hotel/deep-analysis-indicator.vue';
import DeepAnalisysGraphTooltip from '@/modules/deep-analysis/components/hotel/deep-analysis-graph-tooltip.vue';
import Tooltip from '@/modules/common/components/ui-kit/tooltip.vue';
import DeepCompsetAnalysisService, { DeepCompsetAnalysisServiceS } from '@/modules/deep-analysis/deep-analysis.service';

interface RoundedChartOptions extends ChartOptions {
    barRoundness: number
}

@Component({
    components: {
        CustomGraph,
        DeepAnalysisIndicator,
        Tooltip,
        DeepAnalisysGraphTooltip,
    },
    filters: {
        PriceFilter: (value: Price | string) => value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
        DateFilter: (date: Date) => moment(date).format('MMM, D YYYY'),
    },
})
export default class DeepAnalysisItem extends Vue {
    @Inject(DeepCompsetAnalysisServiceS) deepCompsetAnalysisService!: DeepCompsetAnalysisService;

    @Prop({
        type: Object,
        default: () => ({}),
    })
    item!: DeepCompsetAnalysisItem;

    @Prop({
        type: Boolean,
        required: false,
        default: false,
    })
    modal!: boolean;

    @Prop({
        type: Boolean,
    })
    skeleton!: boolean;

    isHovered: boolean = false;

    get labels() {
        if (this.isHovered) {
            return this.normalLabels;
        }
        return this.normalLabels.map((label, i) => (i !== 0 && i !== this.normalLabels.length - 1 ? '' : label));
    }

    get normalLabels() {
        return this.dates.map(date => (date ? moment(date).format('MMM D') : ''));
    }

    get dates() {
        const { dates = [] } = this.item.chartData || {};
        return [...dates].filter((item, i) => i > dates.length / 2);
    }

    get updatedDate() {
        if (this.deepCompsetAnalysisService.provider === 'expedia' && this.item.updatedDateField) {
            return this.item.updatedDateField;
        }
        return this.item.updatedAt;
    }

    get haveStar() {
        return this.item.compsetAverage && this.item.compsetAverage.hasStar;
    }

    handleClick(id: number) {
        if (this.modal) {
            this.$router.push({
                name: `${this.$route.name!}.modal`,
                params: { id: `${id + 1}` },
                query: this.$route.query,
            });
        }
    }

    get brandData() {
        const { data = [] } = this.item.chartData || {};
        return [...data].reverse()
            .filter((item, i) => i > data.length / 2)
            .map(item => item.brand)
            .map(v => v || 0);
    }

    get compsetData() {
        const { data = [] } = this.item.chartData || {};
        return [...data].reverse()
            .filter((item, i) => i > data.length / 2)
            .map(item => item.compsetAvg)
            .map(v => v || 0);
    }

    get chartData(): ChartData {
        return {
            labels: this.labels,
            datasets: [{
                label: 'Brand',
                backgroundColor: '#A1A2FF',
                borderWidth: 0,
                barThickness: 8,
                minBarLength: 5,
                data: this.brandData,
            }, {
                label: 'Comp set Avg.',
                backgroundColor: '#E5E5E5',
                borderWidth: 0,
                barThickness: 8,
                minBarLength: 5,
                data: this.compsetData,
            }],
        };
    }

    get options(): RoundedChartOptions {
        return {
            barRoundness: 1,
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
            tooltips: {
                enabled: false,
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: false,
                        borderDash: [0, 1],
                        offsetGridLines: false,
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        padding: -3,
                        minor: {
                            fontSize: 12,
                        },
                    },
                }],
                yAxes: [{
                    display: false,
                    ticks: {
                        min: 0,
                    },
                }],
            },
        };
    }
}
