

































import Currency from '@/modules/common/components/currency.vue';
import Price from '@/modules/common/types/price.type';
import VALUE_TYPE from '@/modules/deep-analysis/constants/value-type.constant';
import {
    Component, Vue, Prop,
} from 'vue-property-decorator';
import { ChartData, ChartTooltipModelBody, ChartTooltipModel } from 'chart.js';
import moment from 'moment';

interface MyChartTooltipModel extends Omit<ChartTooltipModel, 'labelColors'> {
    title: string[],
    labelColors: {
        backgroundColor: string
    }[]
}

@Component({
    components: {
        Currency,
    },
    filters: {
        PriceFilter: (value: Price | string) => value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
    },
})
export default class DeepAnalisysGraphTooltip extends Vue {
    @Prop({
        type: Object as () => ChartData,
    })
    private chartData!: ChartData;

    @Prop({ required: false, type: Object as () => MyChartTooltipModel })
    tooltipModel!: MyChartTooltipModel;

    @Prop({
        type: String,
        required: true,
    })
    valueType!: VALUE_TYPE;

    @Prop({
        type: String,
        required: true,
    })
    currency!: string;

    @Prop({
        type: Array,
        required: true,
    })
    dates!: Date[];

    @Prop({
        type: String,
        required: false,
    })
    top!: string;

    mounted(): void {
    }

    get style() {
        return this.top ? { top: this.top } : null;
    }

    get titleDate() {
        if (!this.tooltipModel.title || !this.tooltipModel.title[0]) {
            return '';
        }
        const { index } = this.tooltipModel.dataPoints[0];
        return moment(this.dates[index!]).format('MMM D, YYYY');
    }

    getBody(bodyItem: ChartTooltipModelBody) {
        return bodyItem.lines;
    }

    get getTooltipData() {
        const arr: any = [];
        if (this.tooltipModel.body) {
            const bodyLines = this.tooltipModel.body.map(this.getBody);
            const { labelColors } = this.tooltipModel;
            bodyLines.forEach((body: string[], i) => {
                const lineText = body[0];
                const number = lineText.split(': ')[1];
                const value = number === 'NaN' ? '' : number;
                const title = lineText.split(': ')[0];
                arr.push({ txt: title, val: value, color: labelColors[i].backgroundColor });
            });
        }
        return arr;
    }
}
