





import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import SHOW_BY from '@/modules/deep-analysis/constants/show-by-filter.constant';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '@/modules/deep-analysis/deep-analysis-cluster.service';

@Component({
    components: { CustomSelect },
})
export default class ShowByFilter extends Vue {
    @Inject(DeepCompsetAnalysisClusterServiceS) deepCompsetAnalysisClusterService!: DeepCompsetAnalysisClusterService;

    get value() {
        return this.deepCompsetAnalysisClusterService.showBy;
    }

    set value(value: SHOW_BY) {
        this.deepCompsetAnalysisClusterService.showBy = value;
    }

    get items(): Item[] {
        const daysList = [7, 30, 60, 90, 365];
        const bookDateItems = daysList.map(value => ({
            value: `book.${value}`,
            name: `Book dates ${value}`,
        }));
        const stayDateItems = daysList.map(value => ({
            value: `stay.${value}`,
            name: `Stay date ${value}`,
        }));
        return [
            ...stayDateItems,
            ...bookDateItems,
        ];
    }
}
